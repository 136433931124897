<template>
    <section>
        <div class="content">
            <h2 class="has-text-primary-dark">Información Trabajador</h2>
        </div>
        <!-- Breadcrumb -->
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li>
                    <router-link to="/a">Inicio</router-link>
                </li>
                <li>
                    <router-link to="/a/trabajadores">Trabajadores</router-link>
                </li>
                <li class="is-active">
                    <router-link
                        :to="`/a/trabajadores/detail/${trabajador.persona.dni}`"
                    >
                        {{ trabajador.trabajador.nombre }}
                        {{ trabajador.trabajador.apellido_paterno }}
                        {{ trabajador.trabajador.apellido_materno }}
                    </router-link>
                </li>
            </ul>
        </nav>
        <div class="columns">
            <div class="column is-one-third">
                <!-- User Card -->
                <div class="card">
                    <div class="user-circle-section">
                        <div style="width: 100%">
                            <b-dropdown
                                aria-role="list"
                                class="is-pulled-right"
                            >
                                <template #trigger>
                                    <b-icon
                                        icon="dots-vertical"
                                        role="button"
                                        size="is-medium"
                                    ></b-icon>
                                </template>

                                <b-dropdown-item
                                    aria-role="listitem"
                                    @click="viewModal.clave = true"
                                >
                                    <b-icon
                                        icon="lock-reset"
                                        type="is-primary-dark"
                                    ></b-icon>
                                    Restablecer clave
                                </b-dropdown-item>
                                <b-dropdown-item
                                    aria-role="listitem"
                                    @click="confirmDesvincular"
                                >
                                    <b-icon
                                        icon="account-minus"
                                        type="is-primary-dark"
                                    ></b-icon>
                                    Desvincular empleado
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div
                            class="avatar-container"
                            style="margin: -20px auto auto auto"
                        >
                            <img
                                v-if="form.usuario.img"
                                :src="form.usuario.img"
                                alt="user-image"
                            />
                            <no-user-image
                                v-else
                                :trabajador="form.trabajador"
                                size="large"
                            />
                        </div>

                        <div class="mt-3 has-text-centered">
                            <p>
                                <b
                                    >{{ trabajador.trabajador.nombre }}
                                    {{ trabajador.trabajador.apellido_paterno }}
                                    {{
                                        trabajador.trabajador.apellido_materno
                                    }}</b
                                >
                            </p>
                            <p>DNI: {{ trabajador.persona.dni }}</p>
                            <p>
                                Estado:
                                <span
                                    :class="
                                        trabajador.trabajador.activo
                                            ? 'tag is-success'
                                            : 'tag is-danger'
                                    "
                                >
                                    {{
                                        trabajador.trabajador.activo
                                            ? 'Activo'
                                            : 'Inactivo'
                                    }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="card-content">
                        <b-menu>
                            <b-menu-list>
                                <b-menu-item
                                    v-for="item of menu"
                                    :key="item.id"
                                    :icon="item.icon"
                                    :label="item.label"
                                    :active="item.id === selectedMenuItemId"
                                    @click="selectedMenuItemId = item.id"
                                />
                            </b-menu-list>
                        </b-menu>
                    </div>
                </div>
                <!-- End User Card -->
            </div>
            <!-- Informacion Personal -->
            <div class="column" v-if="selectedMenuItemId === 0">
                <div class="card">
                    <div class="card-header">
                        <p class="card-header-title">
                            <span class="has-text-primary-dark">
                                Datos personales
                            </span>
                        </p>
                    </div>
                    <div class="card-content">
                        <form @input="isChangedForm.datosPersonales = true">
                            <div class="columns">
                                <div class="column">
                                    <b-field
                                        label="Usuario"
                                        label-position="on-border"
                                    >
                                        <b-input
                                            v-model="form.usuario.username"
                                            name="usuario"
                                            disabled
                                            required
                                        ></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field
                                        label="Email"
                                        label-position="on-border"
                                    >
                                        <b-input
                                            v-model="form.usuario.email"
                                            name="email"
                                            disabled
                                        ></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field
                                        label="DNI"
                                        label-position="on-border"
                                    >
                                        <b-input
                                            v-model="form.persona.dni"
                                            name="dni"
                                            required
                                            disabled
                                        ></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field
                                        label="Pais"
                                        label-position="on-border"
                                    >
                                        <b-select
                                            v-model="form.persona.pais_id"
                                            name="pais_id"
                                            expanded
                                            required
                                            disabled
                                        >
                                            <option
                                                v-for="pais in paises"
                                                :value="pais.id"
                                                :key="pais.id"
                                            >
                                                {{ pais.descripcion }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field
                                        label="Fecha de nacimiento"
                                        label-position="on-border"
                                    >
                                        <b-datepicker
                                            v-model="
                                                form.trabajador.fecha_nacimiento
                                            "
                                            icon="calendar-today"
                                            :mobile-native="false"
                                            :disabled="!trabajador.trabajador.activo"
                                            @input="
                                                isChangedForm.datosPersonales = true
                                            "
                                        />
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field
                                        label="Nombre"
                                        label-position="on-border"
                                    >
                                        <b-input
                                            v-model="form.trabajador.nombre"
                                            name="nombre"
                                            :disabled="!trabajador.trabajador.activo"
                                            required
                                        ></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field
                                        label="Apellido Paterno"
                                        label-position="on-border"
                                    >
                                        <b-input
                                            v-model="
                                                form.trabajador.apellido_paterno
                                            "
                                            name="apellido_paterno"
                                            :disabled="!trabajador.trabajador.activo"
                                            required
                                        />
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field
                                        label="Apellido Materno"
                                        label-position="on-border"
                                    >
                                        <b-input
                                            v-model="
                                                form.trabajador.apellido_materno
                                            "
                                            name="apellido_materno"
                                            :disabled="!trabajador.trabajador.activo"
                                            required
                                        />
                                    </b-field>
                                </div>
                            </div>
                        </form>
                    </div>
                    <transition name="slide">
                        <div
                            class="card-footer"
                            v-show="isChangedForm.datosPersonales"
                        >
                            <a
                                href="#"
                                @click.prevent="handleSaveDatosPersonales()"
                                class="card-footer-item"
                                >Guardar</a
                            >
                            <a
                                href="#"
                                class="card-footer-item"
                                @click.prevent="cancelarDatosPersonales()"
                                >Cancelar</a
                            >
                        </div>
                    </transition>
                </div>
                <br />
                <div class="card">
                    <div class="card-header">
                        <p class="card-header-title">
                            <span class="has-text-primary-dark">
                                Datos laborales
                            </span>
                        </p>
                    </div>
                    <div class="card-content">
                        <div
                            v-if="camposDatosLaborales.length === 0"
                            class="has-text-grey has-text-centered"
                        >
                            No hay datos laborales asignados
                        </div>
                        <form
                            v-else
                            @input="isChangedForm.datosLaborales = true"
                        >
                            <div
                                v-for="dato of datosLaborales"
                                :key="dato.campo_id"
                            >
                                <div class="columns">
                                    <div class="column">
                                        <b-field
                                            :label="dato.descripcion"
                                            label-position="on-border"
                                        >
                                            <b-select
                                                v-if="dato.opciones"
                                                v-model="dato.valor"
                                                :name="dato.slug"
                                                :disabled="!trabajador.trabajador.activo"
                                                expanded
                                            >
                                                <option
                                                    v-for="opcion of dato.opciones"
                                                    :key="opcion"
                                                >
                                                    {{ opcion }}
                                                </option>
                                            </b-select>
                                            <b-input
                                                v-else
                                                v-model="dato.valor"
                                                :name="dato.slug"
                                                :disabled="!trabajador.trabajador.activo"
                                            ></b-input>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <transition name="slide">
                        <div
                            class="card-footer"
                            v-show="isChangedForm.datosLaborales"
                        >
                            <a
                                href="#"
                                class="card-footer-item"
                                @click.prevent="handleSaveDatosLaborales()"
                                >Guardar</a
                            >
                            <a
                                href="#"
                                class="card-footer-item"
                                @click.prevent="cancelarDatosLaborales()"
                                >Cancelar</a
                            >
                        </div>
                    </transition>
                </div>
            </div>

            <!-- Editar Perfil -->
            <div class="column" v-else-if="selectedMenuItemId === 1">
                <div class="card">
                    <div class="card-header">
                        <p class="card-header-title">
                            <span class="has-text-primary-dark">
                                Permisos
                            </span>
                        </p>
                    </div>
                    <div class="card-content">
                        <b-field>
                            <b-switch
                                size="is-medium"
                                type="is-primary"
                                passive-type="default"
                                v-model="es_admin_tmp"
                                :rounded="false"
                                :disabled="es_admin_tmp"
                                @input="toggleEsAdmin"
                            >
                                <b
                                    >¿Es
                                    <b-tooltip
                                        label="Tiene permisos de ADMINISTRADOR, puede agregar, modificar y eliminar usuarios de la empresa"
                                        type="is-dark"
                                        ><span class="has-text-primary-dark"
                                            >Recursos Humanos</span
                                        ></b-tooltip
                                    >?</b
                                >
                            </b-switch>
                        </b-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <b-modal v-model="viewModal.clave" :width="640">
            <div class="card">
                <div class="card-header">
                    <p class="card-header-title">Restablecer clave</p>
                </div>
                <div class="card-content">
                    <form
                        @submit.prevent="handleRestablecerClave"
                    >
                        <!-- <b-field>
                            <b-checkbox v-model="forms.restablecer_clave.generar_automaticamente">
                                Generar una clave automáticamente
                            </b-checkbox>
                        </b-field> -->
                        <b-field
                            label="Nueva contraseña"
                            label-position="on-border"
                        >
                            <b-input
                                v-model="forms.restablecer_clave.password"
                                type="password"
                                password-reveal
                                required
                            />
                        </b-field>
                        <b-field
                            label="Reingrese nueva contraseña"
                            label-position="on-border"
                        >
                            <b-input
                                v-model="
                                    forms.restablecer_clave.password_confirmation
                                "
                                type="password"
                                password-reveal
                                required
                            />
                        </b-field>
                        <b-button
                            type="is-primary"
                            native-type="submit"
                            size="is-small"
                            :loading="isLoadingRestablecerClave"
                            expanded
                        >
                            Restablecer
                        </b-button>
                    </form>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="viewModal.desvincular">
            <div class="card">
                <div class="card-header">
                    <p class="card-header-title">Desvincular empleado</p>
                </div>
                <div class="card-content">
                    Desvincular empleado
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
import moment from 'moment';
import Swal from 'sweetalert2';

import NoUserImage from '@/components/shared/NoUserImage.vue';
import UploadImage from '@/components/shared/UploadImage.vue';
import Message from '@/utils/Message';

export default {
    components: {
        NoUserImage,
        UploadImage
    },
    data() {
        return {
            viewModal: {
                clave: false,
                desvincular: false
            },
            datosLaborales: [],
            form: {
                usuario: {
                    id: 0,
                    username: '',
                    email: '',
                    ruta_imagen: '',
                    img: ''
                },
                persona: {
                    dni: '',
                    pais_id: ''
                },
                trabajador: {
                    id: 0,
                    nombre: '',
                    apellido_paterno: '',
                    apellido_materno: '',
                    fecha_nacimiento: null,
                    activo: false
                }
            },
            forms: {
                restablecer_clave: {
                    generar_automaticamente: false,
                    password: '',
                    password_confirmation: '',
                    email: '',
                }
            },
            isLoadingRestablecerClave: false,
            isLoadingDesvincular: false,
            isChangedForm: {
                datosPersonales: false,
                datosLaborales: false
            },
            es_admin: false,
            es_admin_tmp: false,
            selectedMenuItemId: 0,
            indefinteToast: null,
            menu: [
                {
                    id: 0,
                    label: 'Información Personal',
                    icon: 'account'
                },
                {
                    id: 1,
                    label: 'Editar Perfil',
                    icon: 'cog'
                }
            ]
        };
    },
    computed: {
        perfil() {
            return this.$store.state.me.perfil;
        },
        trabajador() {
            return this.$store.state.trabajadores.selected;
        },
        esAdmin() {
            return this.$store.state.trabajadores.es_admin;
        },
        isLoadingTrabajador() {
            return this.$store.state.trabajador.isLoading;
        },
        paises() {
            return this.$store.state.paises.data;
        },
        camposDatosLaborales() {
            return this.$store.state.camposDatosLaborales.data;
        }
    },
    watch: {
        trabajador: function() {
            this.loadTrabajador();
            this.fetchTrabajadorPermisos(this.form.trabajador.id);
        },
        camposDatosLaborales: function() {
            this.loadDatosLaborales();
        },
        esAdmin: function(value) {
            this.es_admin = value;
            this.es_admin_tmp = value;
        }
    },
    async mounted() {
        await Promise.all([
            this.fetchTrabajador(),
            this.fetchPaises(),
            this.fetchCamposDatosLaborales()
        ]);
    },
    methods: {
        async fetchTrabajador() {
            await this.$store.dispatch(
                'trabajadores/show',
                this.$route.params.persona_id
            );
            this.loadTrabajador();
        },
        async fetchPaises() {
            await this.$store.dispatch('paises/get');
        },
        async fetchCamposDatosLaborales() {
            await this.$store.dispatch('camposDatosLaborales/get');
            this.loadDatosLaborales();
        },
        async fetchTrabajadorPermisos(id) {
            await this.$store.dispatch('trabajadores/permisos', id);
        },
        async updatePermisos(id, data) {
            const res = await this.$store.dispatch(
                'trabajadores/updatePermisos',
                {
                    id,
                    data
                }
            );
            return res;
        },
        loadTrabajador() {
            const { usuario, trabajador, persona } = this.trabajador;

            this.form.usuario.id = usuario.id;
            this.form.usuario.username = usuario.username;
            this.form.usuario.email = usuario.email;
            this.form.usuario.ruta_imagen = usuario.ruta_imagen;
            this.form.usuario.img = usuario.img
                ? `data:image/jpg;base64,${usuario.img}`
                : null;
            this.form.trabajador.id = trabajador.id;
            this.form.trabajador.nombre = trabajador.nombre;
            if (trabajador.fecha_nacimiento) {
                this.form.trabajador.fecha_nacimiento = moment(
                    trabajador.fecha_nacimiento
                ).toDate();
            }
            this.form.trabajador.apellido_paterno = trabajador.apellido_paterno;
            this.form.trabajador.apellido_materno = trabajador.apellido_materno;
            this.form.trabajador.activo = trabajador.activo;
            this.form.persona.dni = persona.dni;
            this.form.persona.pais_id = persona.pais_id;
        },
        loadDatosLaborales() {
            const { datos_laborales } = this.trabajador;

            this.datosLaborales = this.camposDatosLaborales.map(campo => {
                return {
                    ...campo,
                    campo_id: campo.id,
                    valor:
                        datos_laborales.find(dato => dato.campo.id === campo.id)
                            ?.valor ?? null
                };
            });
        },
        async handleSaveDatosPersonales() {
            const { trabajador, usuario } = this.form;

            const data = {
                usuario,
                trabajador: {
                    ...trabajador,
                    fecha_nacimiento: moment(trabajador.fecha_nacimiento)
                        .format('YYYY-MM-DD')
                        .toString()
                }
            };
            try {
                const { message } = await this.$store.dispatch(
                    'trabajadores/update',
                    data
                );

                this.isChangedForm.datosPersonales = false;
                this.fetchTrabajador();
                Message.showSuccessMessage(this, message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            }
        },
        async handleSaveDatosLaborales() {
            try {
                const data = {
                    trabajador_id: this.form.trabajador.id,
                    datos_laborales: this.datosLaborales
                };

                const { data: results } = await this.$store.dispatch(
                    'datosLaborales/createMany',
                    data
                );

                const listTemplate = results.reduce((acc, curr) => {
                    return `${acc}<li>${curr.message}</li>`;
                }, '');

                this.isChangedForm.datosLaborales = false;
                this.fetchTrabajador();

                Message.showSuccessMessage(this,
                    `<b>Resultado:</b>
                    <ol>
                        ${listTemplate}
                    </ol>`
                );
            } catch (err) {
                Message.showErrorMessage(this, 'Error al actualizar datos laborales');
            }
        },
        async toggleEsAdmin() {
            const { value } = await Swal.fire({
                title: 'Cambiar permisos de usuario',
                icon: 'warning',
                text: 'Cambiar permisos de usuario',
                confirmButtonText: 'Confirmar',
                showCancelButton: true
            });

            if (!value) {
                this.es_admin_tmp = this.es_admin;
                return;
            }

            try {
                Swal.fire({
                    didOpen: () => Swal.showLoading()
                });

                const res = await this.updatePermisos(this.form.trabajador.id, {
                    es_admin: !this.es_admin
                });

                // this.loadData(res.data);

                Swal.fire({
                    title: res.message,
                    icon: 'success'
                });
            } catch (err) {
                this.es_admin_tmp = this.es_admin;
                Swal.fire({
                    title: err.message,
                    icon: 'error'
                });
            }
        },
        async handleRestablecerClave() {
            const { password, password_confirmation } = this.forms.restablecer_clave;

            if (password !== password_confirmation) {
                Message.showErrorMessage(this, 'Las contraseñas no son iguales');
                return;
            }

            try {
                this.isLoadingRestablecerClave = true;

                const res = await this.$store.dispatch('usuarios/resetPassword', {
                    id: this.form.usuario.id,
                    ...this.forms.restablecer_clave
                });

                this.clearRestablecerClave();
                this.viewModal.clave = false;
                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.isLoadingRestablecerClave = false;
            }
        },
        async handleDesvincular() {
            try {
                this.isLoadingDesvincular = true;

                const data = {
                    usuario: this.form.usuario,
                    trabajador: {
                        ...this.form.trabajador,
                        activo: false,
                    }
                }
                const res = await this.$store.dispatch('trabajadores/update', data);
                Message.showSuccessMessage(this, res.message);
                this.fetchTrabajador();
            } catch (err) {
                Message.showErrorMessage(this, `Error: ${err.message}`);
            } finally {
                this.isLoadingDesvincular = false;
                this.closeIndefinite();
            }
        },
        confirmDesvincular() {
            /* Swal.fire({
                title: 'Desvincular trabajador',
                text: 'Estas seguro que deseas <b>desvincular</b> a este trabajador? Esta acción es irreversible.',
                confirmButtonText: 'Devincular',
                confirmButtonColor: 'red'    
            }); */
            this.$buefy.dialog.confirm({
                title: 'Desvincular trabajador',
                message: 'Estas seguro que deseas <b>desvincular</b> a este trabajador? Esta acción es irreversible.',
                confirmText: 'Desvincular',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: async (value, { close }) => {
                    this.indefinteToast = this.$buefy.toast.open({
                        message: 'Procesando solicitud...',
                        indefinite: true,
                    });
                    await this.handleDesvincular();
                }
            })
        },
        closeIndefinite() {
            if (this.indefinteToast) {
                this.indefinteToast.close();
                this.indefinteToast = null;
            }
        },
        clearRestablecerClave() {
            this.forms.restablecer_clave.password = '';
            this.forms.restablecer_clave.password_confirmation = '';
        },
        cancelarDatosPersonales() {
            this.fetchTrabajador();
            this.isChangedForm.datosPersonales = false;
        },
        cancelarDatosLaborales() {
            this.fetchCamposDatosLaborales();
            this.isChangedForm.datosLaborales = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.user-circle-section {
    padding: 25px;
    border-radius: 20% 20% 500% 500%/20% 20% 90% 90%;
    width: 100%;
    background-color: $primary;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
}
</style>
